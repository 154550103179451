'use client'
import { clsx } from 'clsx'
import DateTime from '@/components/pages/filter/hero/self-driving/DateTime'
import Location from '@/components/pages/filter/hero/self-driving/FilterLocationPopover'
import { Button } from '@/components/ui/button'
import { SearchSvg } from '@/icons-svg/HomeSvgIcons'
import { Link } from '@/navigation'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

interface SelfDrivingProps {
  isHome?: boolean
}

export default function SelfDriving({ isHome }: SelfDrivingProps) {
  const t = useTranslations('home.hero')
  const [address, setAddress] = useState('')

  return (
    <div
      className={clsx(
        'grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 pt-4',
        isHome ? 'mt-8' : ''
      )}
    >
      <Location view="home" setAddress={setAddress} />
      <div className="block sm:flex sm:items-center sm:justify-end">
        <DateTime />
        <Link href={`/filter?address=${address}`}>
          <Button className="bg-brand-solid rounded-2xl h-16 sm:w-16 w-full">
            <div className="p-5 sm:block hidden">
              <SearchSvg />
            </div>
            <div className="p-5 block sm:hidden font-semibold text-base text-white">
              {t('tim_kiem')}
            </div>
          </Button>
        </Link>
      </div>
    </div>
  )
}
