function HopSoSvg() {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4735 12.5733L14.4268 14M2.66683 4.66666V11.3333M13.3335 4.66666V6.66666C13.3335 7.40333 12.7368 8 12.0002 8H2.66683M8.00017 4.66666V11.3333M12.0475 12.5727H13.4735C13.9995 12.5727 14.4268 12.1453 14.4268 11.6193C14.4268 11.0933 13.9995 10.6667 13.4735 10.6667H12.0475V14M14.2763 2.39052C14.797 2.91122 14.797 3.75544 14.2763 4.27614C13.7556 4.79684 12.9114 4.79684 12.3907 4.27614C11.87 3.75545 11.87 2.91122 12.3907 2.39052C12.9114 1.86983 13.7556 1.86983 14.2763 2.39052ZM8.94297 2.39052C9.46367 2.91122 9.46367 3.75544 8.94297 4.27614C8.42227 4.79684 7.57805 4.79684 7.05735 4.27614C6.53665 3.75545 6.53665 2.91122 7.05735 2.39052C7.57805 1.86983 8.42227 1.86983 8.94297 2.39052ZM3.60964 2.39052C4.13034 2.91122 4.13034 3.75544 3.60964 4.27614C3.08894 4.79684 2.24472 4.79684 1.72402 4.27614C1.20332 3.75545 1.20332 2.91122 1.72402 2.39052C2.24472 1.86983 3.08894 1.86983 3.60964 2.39052ZM8.94297 11.7239C9.46367 12.2446 9.46367 13.0888 8.94297 13.6095C8.42227 14.1302 7.57805 14.1302 7.05735 13.6095C6.53665 13.0888 6.53665 12.2446 7.05735 11.7239C7.57805 11.2032 8.42227 11.2032 8.94297 11.7239ZM3.60964 11.7239C4.13034 12.2446 4.13034 13.0888 3.60964 13.6095C3.08894 14.1302 2.24472 14.1302 1.72402 13.6095C1.20332 13.0888 1.20332 12.2446 1.72402 11.7239C2.24472 11.2032 3.08894 11.2032 3.60964 11.7239Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  )
}

function SoGheSvg() {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.41875 14.6668H11.6094M8.66667 6.66683H11.3333M2.67687 1.3335H3.12553C4.02217 1.3335 4.81265 1.9772 5.0703 2.91717L6.5219 8.21298C6.60779 8.52631 6.87129 8.66683 7.17016 8.66683H11.0021C12 8.66683 13.2481 9.33068 13.8213 10.3886C14.3333 11.3335 13.8213 12.4446 12.7577 12.4446H4.78169C3.73857 12.4446 2.86517 11.5795 2.76137 10.4435L2.00342 2.14794C1.96358 1.71188 2.27645 1.3335 2.67687 1.3335Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  )
}

function NhienLieuSvg() {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99984 10H7.6665M2.33317 14V3.33333C2.33317 2.59667 2.92984 2 3.6665 2H8.99984C9.7365 2 10.3332 2.59667 10.3332 3.33333V14M12.9998 3.33333L13.9425 4.276C14.1925 4.526 14.3332 4.86533 14.3332 5.21867V11.6667C14.3332 12.2187 13.8852 12.6667 13.3332 12.6667C12.7812 12.6667 12.3332 12.2187 12.3332 11.6667V10.6667C12.3332 10.2987 12.0345 10 11.6665 10H10.3332M14.3332 8H13.0885C12.6878 8 12.3772 7.64867 12.4272 7.25067L12.5938 5.91733C12.6352 5.584 12.9185 5.33333 13.2552 5.33333H14.3332M1.6665 14H10.9998M4.33317 4.66667H8.33317V7.33333H4.33317V4.66667Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </svg>
  )
}

function CarSvg() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16663 10.8335H6.66663M1.66663 7.50016L3.33329 8.3335L4.39216 5.15689C4.61072 4.50121 4.72 4.17336 4.9227 3.93098C5.10169 3.71694 5.33156 3.55126 5.59121 3.44914C5.88526 3.3335 6.23083 3.3335 6.92198 3.3335H13.0779C13.7691 3.3335 14.1147 3.3335 14.4087 3.44914C14.6684 3.55126 14.8982 3.71694 15.0772 3.93098C15.2799 4.17336 15.3892 4.50121 15.6078 5.15689L16.6666 8.3335L18.3333 7.50016M13.3333 10.8335H15.8333M5.66663 8.3335H14.3333C15.7334 8.3335 16.4335 8.3335 16.9683 8.60598C17.4387 8.84566 17.8211 9.22812 18.0608 9.69852C18.3333 10.2333 18.3333 10.9334 18.3333 12.3335V14.5835C18.3333 14.9707 18.3333 15.1643 18.3013 15.3253C18.1698 15.9865 17.6529 16.5033 16.9918 16.6348C16.8308 16.6668 16.6372 16.6668 16.25 16.6668H15.8333C14.9128 16.6668 14.1666 15.9206 14.1666 15.0002C14.1666 14.77 13.9801 14.5835 13.75 14.5835H6.24996C6.01984 14.5835 5.83329 14.77 5.83329 15.0002C5.83329 15.9206 5.0871 16.6668 4.16663 16.6668H3.74996C3.36275 16.6668 3.16914 16.6668 3.00814 16.6348C2.34699 16.5033 1.83016 15.9865 1.69865 15.3253C1.66663 15.1643 1.66663 14.9707 1.66663 14.5835V12.3335C1.66663 10.9334 1.66663 10.2333 1.93911 9.69852C2.17879 9.22812 2.56124 8.84566 3.03165 8.60598C3.56643 8.3335 4.26649 8.3335 5.66663 8.3335Z"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.66667"
      />
    </svg>
  )
}

export { HopSoSvg, SoGheSvg, NhienLieuSvg, CarSvg }
