'use client'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

export function EmailRegister() {
  const [email, setEmail] = useState<string>('')
  const t = useTranslations('home.EmailRegister')

  return (
    <section className="bg-base-gray-50 py-24 flex justify-center overflow-x-hidden text-center">
      <div className="container px-8">
        <div className="">
          <h3 className="text-2xl font-semibold">{t('title')}</h3>
          <p className="text-base-gray-500 mt-5">{t('description')}</p>
        </div>
        <div className="sm:flex items-center sm:justify-center gap-4 mt-8">
          <Input
            className="w-full p-3 rounded-lg border border-base-100 sm:max-w-sm h-12"
            placeholder={t('email_placeholder')}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className="w-full mt-4 sm:w-auto sm:mt-0 bg-brand-solid p-3 rounded-lg text-white font-semibold"
            type="submit"
            disabled={email.trim() === ''}
          >
            {t('dang_ky')}
          </Button>
        </div>
      </div>
    </section>
  )
}
