import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/home/dash-line.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/home/icon-box-1.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/home/icon-box-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/home/icon-box-3.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/home/iphone-mockup.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/icons/icon-app-store.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/icons/icon-google-play.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/no_photo.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/filter/hero/self-driving/SelfDriving.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmailRegister"] */ "/app/src/components/pages/home/email-register/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/home/instructions/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/pages/home/landmarks/LandmarksSwiper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/pages/home/list-cars/ListCars.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs","TabsList","TabsTrigger","TabsContent"] */ "/app/src/components/ui/tabs.tsx");
