'use client';
import Image, { StaticImageData } from 'next/image'
import { Key } from 'react'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { testimonials } from './data'
import 'swiper/css'
import 'swiper/css/navigation'
import { CarSvg } from '@/icons-svg/SvgIconCar'
import {Link} from "@/navigation";

type TestimonialProps = {
  name: string
  position: string
  alt: string
  image: StaticImageData
}

function CommonSlide({ image, alt, name, position }: TestimonialProps) {
  return (
    <Link href={"#"}>
      <div className="bg-white gap-y-5 grid">
        <div className="w-full rounded-3xl min-w-60 h-[256px] relative">
          <Image
            alt={alt}
            className="object-cover rounded-3xl"
            fill={true}
            src={image}
            loading="lazy"
          />
        </div>
        <div className="gap-y-2 grid">
          <h6 className="font-bold text-base text-base-gray-900">{name}</h6>
          <div className="text-sm text-slate-500 flex items-center">
            <CarSvg/>
            <span className="ml-1.5">{position}</span>
          </div>
        </div>
      </div>
    </Link>
  )
}

function TestimonialSwiper() {
  return (
    <div className="swiper" id="swiper_two">
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        loop
        modules={[Autoplay, Navigation]}
        navigation={{
          nextEl: '.swiper-custom-next',
          prevEl: '.swiper-custom-prev',
        }}
        slidesPerView={4}
        spaceBetween={30}
      >
        {(testimonials ?? []).map(
          (
            item: {
              description: string
              image: StaticImageData
              name: string
              position: string
            },
            idx: Key | null | undefined
          ) => (
            <SwiperSlide key={idx}>
              <CommonSlide
                image={item.image}
                name={item.name}
                alt={item.description}
                position={item.position}
              />
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  )
}

export default TestimonialSwiper
