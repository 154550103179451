import { StaticImageData } from 'next/image'
import { PlanItem, PricingCard } from './types'
import hanoi from '@/assets/images/home/ha-noi.webp'
import hochiminh from '@/assets/images/home/ho-chi-minh.webp'
import hue from '@/assets/images/home/hue.webp'
import danang from '@/assets/images/home/da-nang.webp'

type Testimonials = {
  name: string
  position: string
  description: string
  image: StaticImageData
}

type AccordionData = {
  title: string
  description: string
}

type FooterLink = {
  [title: string]: {
    links: Array<string>
  }
}

const testimonials: Array<Testimonials> = [
  {
    name: 'Hồ Chí minh',
    position: '1000+ xe',
    description:
      'Thành phố Hồ Chí Minh - đầu tàu kinh tế của Việt Nam, là trung tâm kinh tế, chính trị và văn hóa của cả nước. Nơi đây sở hữu một nền kinh tế đa ngành, hệ thống thương mại đa dạng, và các điểm du lịch nổi tiếng.',
    image: hochiminh,
  },
  {
    name: 'Hà Nội',
    position: '200+ xe',
    description:
      'Hà Nội, thủ đô Việt Nam, trung tâm chính trị, kinh tế và văn hóa hàng đầu cả nước. Hà Nội là điểm du lịch hấp dẫn với bảo tàng, công trình lịch sử như lăng Hồ Chủ Tịch, hồ Gươm, Văn miếu Quốc Tử Giám, Cầu Long Biên,... Bạn cũng có thể khám phá 36 phố phường để tận hưởng trọn vẹn những nét truyền thống và đặc trưng của Hà Nội.',
    image: hanoi,
  },
  {
    name: 'Đà Nẵng',
    position: '312+ xe',
    description:
      'Nằm dọc theo bờ biển miền Trung Việt Nam, Đà Nẵng nổi tiếng với những bãi biển đẹp, những cây cầu hiện đại và những di sản văn hóa độc đáo. Thành phố được mệnh danh là "thành phố đáng sống" và "thành phố đáng đến" bởi sự phát triển năng động, môi trường sống trong lành và cảnh quan thiên nhiên tuyệt đẹp.',
    image: danang,
  },
  {
    name: 'Huế',
    position: '108+ xe',
    description:
      'Nằm bên bờ sông Hương thơ mộng, Huế mang vẻ đẹp cổ kính, trầm mặc với những lăng tẩm uy nghi, những ngôi chùa linh thiêng và những cung điện tráng lệ. Nơi đây từng là Kinh đô của Việt Nam dưới triều Nguyễn trong suốt 130 năm (1802 - 1945), lưu giữ nhiều giá trị lịch sử, văn hóa và kiến trúc độc đáo.',
    image: hue,
  },
]

const FAQContent: Array<AccordionData> = [
  {
    title: ' Can I use this template for my client?',
    description:
      ' Yup, the marketplace license allows you to use this theme in any end products. For more information on licenses, please refere license terms on marketplace. ',
  },
  {
    title: ' Can this theme work with WordPress?',
    description:
      " No. This is a HTML template. It won't directly with WordPress, though you can convert this into WordPress compatible theme. ",
  },
  {
    title: ' How do I get help with the theme?',
    description:
      ' Use our dedicated support email (support@coderthemes.com) to send your issues or feedback. We are here to help anytime. ',
  },
  {
    title: ' Will you regularly give updates of Prompt ?',
    description:
      ' Yes, We will update the Prompt regularly. All the future updates would be available without any cost. ',
  },
]

const plans: Array<PlanItem> = [
  {
    id: 1,
    name: 'Starter',
    price: '49',
    duration: '/ month',
    features: [
      'Up to 600 minutes usage time',
      'Use for personal only',
      'Add up to 10 attendees',
      'Technical support via email',
    ],
    isRecommended: false,
  },
  {
    id: 2,
    name: 'Professional',
    price: '99',
    duration: '/ month',
    features: [
      'Up to 6000 minutes usage time',
      'Use for personal or a commercial client',
      'Add up to 100 attendees',
      'Up to 5 teams',
      'Technical support via email',
    ],
    isPopular: true,
    isRecommended: true,
  },
  {
    id: 3,
    name: 'Enterprise',
    price: '599',
    duration: '/ month',
    features: [
      'Unlimited usage time',
      'Use for personal or a commercial client',
      'Add Unlimited attendees',
      '24x7 Technical support via phone',
      'Technical support via email',
    ],
    isRecommended: false,
  },
]

const pricingCards: PricingCard = {
  Starter: {
    duration: 500,
    price: '49',
    features: [
      'Up to 600 minutes usage time',
      'Use for personal only',
      'Add up to 10 attendees',
      'Technical support via email',
    ],
  },
  Professional: {
    duration: 700,
    price: '99',
    features: [
      'Up to 6000 minutes usage time',
      'Use for personal or a commercial',
      'Add up to 100 attendees',
      'Up to 5 teams',
      'Technical support via email',
    ],
  },
  Enterprise: {
    duration: 900,
    price: '599',
    features: [
      'Unlimited usage time',
      'Use for personal or a commercial',
      'Add Unlimited attendees',
      '24x7 Technical support via phone',
      'Technical support via email',
    ],
  },
}

const footerLinks: FooterLink = {
  platform: {
    links: ['Demo', 'Pricing', 'Integrations', 'Status'],
  },
  'Knowledge Base': {
    links: ['Blog', 'Help Center', 'Sales Tools catalog', 'API'],
  },
  company: {
    links: ['About us', 'Career', 'Contact Us'],
  },
  legal: {
    links: ['Usage Policy', 'Privacy Policy', 'Terms of Service', 'Trust'],
  },
}

export { testimonials, plans, FAQContent, footerLinks, pricingCards }
